import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { graphql, Link as GatsbyLink } from "gatsby"
import React from "react"
import Helmet from "react-helmet"
import Action from "../components/action"
import AnimateOnSeen from "../components/animate-on-seen"
import CTAButton from "../components/CTAButton"
import Hero from "../components/hero"
import Page from "../components/page"
import Partners from "../components/partners"
import Personas from "../components/personas"
import { Statistics } from "../components/statistics"
import Testimonials from "../components/testimonials"
import VideoSection from "../components/video-section"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import { buildMeta } from "../helpers"
import dotsGroupImg from "../img/dots-group.svg"
import dotsSquareImg from "../img/dots-square.png"
import { colors } from "../theme"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const useStyles = makeStyles(theme => ({
  borderLeftRadius: {
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2),
  },
  background: {
    opacity: 0.6,
  },
  personasContainer: {
    overflowX: "hidden",
  },
}))

export const HomePageTemplate = ({
  data: { hero, partners, video, testimonials, actions, solutions },
  statisticsData,
  onPrimaryActionVisibilityChange,
}) => {
  const classes = useStyles()
  const theme = useTheme()

  const md = useMediaQuery(theme.breakpoints.up("md"))
  const personaRef = React.useRef(null)

  const heroCtas = []

  if (hero) {
    heroCtas.push(
      <Box key={0} mr={1} mb={1} color={colors.cta} minWidth={135}>
        <CTAButton
          size="large"
          fullWidth
          className="content pcta-hero-learn-more"
          id="Learn More - Primary - Hero"
          variant="outlined"
          color="inherit"
          onClick={() => {
            window.scrollTo({
              top:
                personaRef.current.getBoundingClientRect().top -
                theme.spacing(5),
              behavior: "smooth",
            })
          }}
        >
          Learn more
        </CTAButton>
      </Box>
    )
  }

  return (
    <main>
      {hero && (
        <Hero
          {...hero}
          customCTAs={heroCtas}
          onPrimaryActionVisibilityChange={onPrimaryActionVisibilityChange}
        />
      )}
      {partners && <Partners {...partners} />}
      {solutions && (
        <Box
          ref={personaRef}
          className={classes.personasContainer}
          position="relative"
        >
          <Box
            className={classes.background}
            position="absolute"
            height="100%"
            width="100%"
            bgcolor={colors.grayLightest}
          />
          <Container maxWidth="lg">
            <Personas {...solutions} />
          </Container>
        </Box>
      )}
      {statisticsData && (
        <Container maxWidth="lg">
          <Statistics
            title="Smarter, Faster, Better"
            description="With the numbers to prove it"
            data={statisticsData}
          />
        </Container>
      )}
      {testimonials && !!testimonials.length && (
        <Box position="relative">
          <Box
            className={`${classes.background} ${classes.borderLeftRadius}`}
            position="absolute"
            right={0}
            zIndex={-1}
            height="100%"
            width={md ? "50%" : "100%"}
            bgcolor={colors.grayLightest}
          />
          <Container maxWidth="lg">
            <Box py={6}>
              <AnimateOnSeen animeConfig={{ ...TRANSLATE_ANIME_CONFIG }}>
                <Box pb={3}>
                  <Typography
                    variant="h4"
                    align="center"
                    color="textPrimary"
                    gutterBottom
                  >
                    See what others are saying about <strong>Ship.Cars</strong>
                  </Typography>
                </Box>
              </AnimateOnSeen>
              <Box position="relative">
                {md && (
                  <Box
                    position="absolute"
                    height={184}
                    width={184}
                    top={-theme.spacing(3)}
                    right={-theme.spacing(3)}
                    style={{
                      zIndex: 0,
                      backgroundImage: `url(${dotsSquareImg})`,
                      backgroundRepeat: "no-repeat",
                    }}
                  />
                )}
                {md && (
                  <Box
                    position="absolute"
                    height={217}
                    width={217}
                    zIndex={-1}
                    top={theme.spacing(1)}
                    left={-theme.spacing(7)}
                    style={{
                      backgroundImage: `url(${dotsGroupImg})`,
                      backgroundRepeat: "no-repeat",
                      opacity: 0.5,
                    }}
                  />
                )}
                <Testimonials items={testimonials} />
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {video && <VideoSection {...video} />}
      {actions &&
        actions.map((action, index) => (
          <Action
            key={index}
            className={`action-section-${index}`}
            {...action}
          />
        ))}
    </main>
  )
}

const HomePage = ({
  data: {
    headerData,
    footerData,
    homePageData,
    subscribeData,
    statisticsData,
    siteData,
  },
}) => {
  const [showNavPrimaryAction, setShowNavPrimaryAction] = React.useState(false)
  const { hero, seo } = homePageData.frontmatter
  const { siteUrl } = siteData.siteMetadata

  let primaryAction

  if (hero && hero.ctas) {
    const primaryCTA = hero.ctas.find(({ primary }) => primary)

    if (primaryCTA) {
      const { action, label, variant } = primaryCTA

      primaryAction = (
        <CTAButton
          size="large"
          variant={variant}
          color="primary"
          fullWidth
          component={action && action.startsWith("/") ? AdapterLink : "a"}
          {...(action && action.startsWith("/")
            ? {
                to: action,
              }
            : {
                href: action,
              })}
        >
          {label}
        </CTAButton>
      )
    }
  } else {
    primaryAction = null
  }

  const loginAction = (
    <Box color={colors.cta}>
      <CTAButton
        size="large"
        fullWidth
        variant="outlined"
        color="inherit"
        href="/app/login"
      >
        Login
      </CTAButton>
    </Box>
  )

  const handlePrimaryActionVisibilityChange = isVisible => {
    setShowNavPrimaryAction(!isVisible)
  }

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        meta={[
          ...buildMeta(
            siteData.siteMetadata,
            seo,
            hero ? hero.featuredimage : null
          ),
          ...(seo.metatags ? seo.metatags : []),
        ]}
      >
        <link rel="canonical" href={`${siteUrl}`} />
        {seo.schemamarkup && (
          <script type="application/ld+json">{seo.schemamarkup}</script>
        )}
      </Helmet>
      <Page
        primaryAction={
          showNavPrimaryAction ? primaryAction || loginAction : loginAction
        }
        headerData={headerData}
        footerData={footerData}
      >
        <HomePageTemplate
          data={homePageData.frontmatter}
          statisticsData={statisticsData}
          onPrimaryActionVisibilityChange={handlePrimaryActionVisibilityChange}
        />
        {/* <Subscribe data={subscribeData} /> */}
      </Page>
    </React.Fragment>
  )
}

export default HomePage

export const pageQuery = graphql`
  query HomePageQuery {
    ...PageFragment
    ...SubscribeFragment
    ...StatisticsFragment
    siteData: site {
      siteMetadata {
        siteUrl
        env
      }
    }
    homePageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/index.md/" }
    ) {
      frontmatter {
        seo {
          title
          description
          keywords
          schemamarkup
          metatags {
            name
            content
          }
        }
        hero {
          title {
            label
            variant
          }
          description
          featuredimage {
            source {
              childImageSharp {
                fluid(maxWidth: 2048) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          ctas {
            primary
            label
            action
            variant
          }
        }
        partners {
          title
          items {
            alt
            source {
              childImageSharp {
                fixed(height: 40) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        solutions {
          description
          title
          items {
            description
            thumbnail {
              source {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 270) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            title
            link
          }
        }
        video {
          id
        }
        actions {
          title
          description
          ctas {
            label
            action
            variant
          }
        }
        testimonials {
          content
          thumbnail {
            source {
              childImageSharp {
                fluid(maxHeight: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          author {
            jobtitle
            name
          }
          logo {
            active {
              childImageSharp {
                fixed(height: 35) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            inactive {
              childImageSharp {
                fixed(height: 35) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            alt
          }
        }
      }
    }
  }
`
