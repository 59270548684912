import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Box from "@material-ui/core/Box"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import withWidth, { isWidthUp } from "@material-ui/core/withWidth"
import { withStyles, withTheme } from "@material-ui/styles"
import anime from "animejs/lib/anime.es.js"
import { Link as GatsbyLink } from "gatsby"
import { default as React } from "react"
import { colors } from "../theme"
import PreviewCompatibleImage from "./preview-compatible-image"

const AdapterLink = React.forwardRef((props, ref) => (
  <GatsbyLink innerRef={ref} {...props} />
))

const IMAGE_MAX_HEIGHT = 270
const CONTENT_HEIGHT = 116

const useDescriptionStyles = makeStyles(theme => ({
  description: {
    opacity: 0,
  },
}))

const AnimatedPersonaCardDescription = ({ className, description }) => {
  const classes = useDescriptionStyles()

  React.useEffect(() => {
    anime.remove(`.personas-section .${className}`)
    anime({
      targets: `.personas-section .${className}`,
      translateY: 15,
      direction: "reverse",
      opacity: [1, 0],
      easing: "easeInOutSine",
      duration: 450,
    })
  }, [className])

  return (
    <Typography
      className={`${classes.description} ${className}`}
      variant="body1"
      color="textSecondary"
      gutterBottom
    >
      {description}
    </Typography>
  )
}

const styles = {
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardActionArea: {
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    flexGrow: 1,
    flexShrink: 1,
  },
  media: {
    width: "110%",
    transform: "translateX(-5%)",
    flexGrow: 0,
    flexShrink: 1,
  },
  image: {
    maxHeight: IMAGE_MAX_HEIGHT,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
}

class PersonaCard extends React.Component {
  constructor(props) {
    super(props)

    this.handleCardEnter = this.handleCardEnter.bind(this)
    this.handleCardLeave = this.handleCardLeave.bind(this)

    this.descriptionRef = React.createRef(null)

    this.state = {
      selectedCard: false,
    }
  }

  handleCardEnter() {
    const { enableAnimations, width, index } = this.props

    if (enableAnimations) {
      this.setState({
        selectedCard: true,
      })

      anime.remove(`.personas-section .bar-${index}`)
      anime({
        targets: `.personas-section .bar-${index}`,
        width: "100%",
        duration: 400,
        easing: "easeInOutQuad",
      })

      anime.remove(`.personas-section .card-${index}`)
      anime({
        targets: `.personas-section .card-${index}`,
        translateY: -10,
        duration: 300,
      })

      anime.remove(`.personas-section .learn-more-arrow-${index}`)
      anime({
        targets: `.personas-section .learn-more-arrow-${index}`,
        translateX: 5,
        duration: 300,
      })

      if (isWidthUp("md", width)) {
        const tl = anime.timeline({
          easing: "easeOutExpo",
          duration: 400,
        })

        this.currentDescriptionHeight = this.descriptionRef.current.clientHeight

        anime.remove(`.personas-section .card-image-${index}`)
        tl.add(
          {
            targets: `.personas-section .card-image-${index}`,
            maxHeight: `${IMAGE_MAX_HEIGHT - this.currentDescriptionHeight}px`,
          },
          0
        )

        anime.remove(`.personas-section .card-content-${index}`)
        tl.add(
          {
            targets: `.personas-section .card-content-${index}`,
            flexBasis: `${CONTENT_HEIGHT + this.currentDescriptionHeight}px`,
          },
          0
        )

        anime.remove(`.personas-section .card-media-${index}`)
        tl.add(
          {
            targets: `.personas-section .card-media-${index}`,
            width: `100%`,
            translateX: "0%",
          },
          0
        )
      }
    }
  }

  handleCardLeave() {
    const { enableAnimations, width, index } = this.props

    if (enableAnimations) {
      this.setState({ selectedCard: false })

      anime.remove(`.personas-section .bar-${index}`)
      anime({
        targets: `.personas-section .bar-${index}`,
        width: "0%",
        duration: 400,
        easing: "easeInOutQuad",
      })

      anime.remove(`.personas-section .card-${index}`)
      anime({
        targets: `.personas-section .card-${index}`,
        translateY: 0,
        duration: 300,
      })

      anime.remove(`.personas-section .learn-more-arrow-${index}`)
      anime({
        targets: `.personas-section .learn-more-arrow-${index}`,
        translateX: 0,
        duration: 300,
      })

      if (isWidthUp("md", width)) {
        var tl = anime.timeline({
          easing: "easeOutExpo",
          duration: 400,
        })

        anime.remove(`.personas-section .card-image-${index}`)
        tl.add(
          {
            targets: `.personas-section .card-image-${index}`,
            maxHeight: `${IMAGE_MAX_HEIGHT}px`,
          },
          0
        )

        anime.remove(`.personas-section .card-content-${index}`)
        tl.add(
          {
            targets: `.personas-section .card-content-${index}`,
            flexBasis: `${CONTENT_HEIGHT}px`,
          },
          0
        )

        anime.remove(`.personas-section .card-media-${index}`)
        tl.add(
          {
            targets: `.personas-section .card-media-${index}`,
            width: `110%`,
            translateX: "-5%",
          },
          0
        )
      }
    }
  }

  render() {
    const {
      className,
      classes,
      width,
      index,
      title,
      description,
      link,
      thumbnail,
    } = this.props
    const { selectedCard } = this.state

    return (
      <Box
        className={className}
        height="100%"
        width="100%"
        maxWidth={390}
        onMouseEnter={this.handleCardEnter}
        onMouseLeave={this.handleCardLeave}
      >
        <Card
          className={`${classes.card} card-${index}`}
          raised={!!selectedCard}
        >
          <CardActionArea
            className={`${classes.cardActionArea} card-action-${index + 1}`}
            id={`${title}`}
            to={`/${link}`}
            component={AdapterLink}
          >
            {thumbnail && thumbnail.source && (
              <CardMedia className={`${classes.media} card-media-${index}`}>
                <PreviewCompatibleImage
                  className={`${classes.image} card-image-${index}`}
                  type="fluid"
                  image={thumbnail.source}
                  alt={thumbnail.alt}
                  rawImageProps={{
                    width: 390,
                    height: 220,
                  }}
                />
              </CardMedia>
            )}
            <CardContent
              className={`${classes.content} card-content-${index}`}
              style={{
                flexBasis: isWidthUp("md", width) ? CONTENT_HEIGHT : "auto",
              }}
            >
              <Box flexGrow={1}>
                <Typography variant="h5" color="textPrimary" gutterBottom>
                  <strong>{title}</strong>
                </Typography>
                <Box
                  ref={this.descriptionRef}
                  top={0}
                  left={0}
                  position="absolute"
                  visibility="hidden"
                  zIndex={-1}
                  px={2}
                >
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                  >
                    {description}
                  </Typography>
                </Box>
                {selectedCard && isWidthUp("md", width) && (
                  <AnimatedPersonaCardDescription
                    className={`card-description-${index}`}
                    description={description}
                  />
                )}
                {!isWidthUp("md", width) && (
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    gutterBottom
                  >
                    {description}
                  </Typography>
                )}
              </Box>
              <Box color={colors.link}>
                <Typography variant="subtitle2" color="inherit">
                  <strong>Learn more</strong>{" "}
                  <FontAwesomeIcon
                    className={`learn-more-arrow-${index}`}
                    icon={faArrowRight}
                    size="xs"
                  />
                </Typography>
              </Box>
            </CardContent>
            <Box flexGrow={0} flexShrink={0} height={5}>
              <Box
                className={`bar-${index}`}
                width={0}
                height="100%"
                bgcolor={colors.link}
              />
            </Box>
          </CardActionArea>
        </Card>
      </Box>
    )
  }
}

export default withWidth()(withTheme(withStyles(styles)(PersonaCard)))
