import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import { default as React } from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import MobileStepper from "../components/MobileStepper"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import AnimateOnSeen from "./animate-on-seen"
import PreviewCompatibleImage from "./preview-compatible-image"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: "uppercase",
  },
}))

const Partners = ({ items, title }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const swipes = []

  const sm = useMediaQuery(theme.breakpoints.up("sm"))
  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  let logosPerSwipe

  if (lg) {
    logosPerSwipe = 6
  } else if (md) {
    logosPerSwipe = 3
  } else if (sm) {
    logosPerSwipe = 2
  } else {
    logosPerSwipe = 1
  }

  function handleStepChange(step) {
    setActiveStep(step)
  }

  let currentSwipe = { items: [] }

  items.forEach((item, index) => {
    if (index % logosPerSwipe === 0) {
      if (currentSwipe.items.length) {
        swipes.push(currentSwipe)
        currentSwipe = { items: [] }
      }
    }

    currentSwipe.items.push(item)
  })

  if (currentSwipe.items.length) {
    swipes.push(currentSwipe)
  }

  const swipesElements = swipes.map((swipe, index) => (
    <Box
      key={index}
      py={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {swipe.items.map((item, index) => (
        <Box
          key={index}
          className="partner-logo"
          px={swipe.items.length ? 4 : 0}
        >
          <PreviewCompatibleImage
            type="fixed"
            image={item.source}
            alt={item.alt}
            gatsbyImageProps={{
              loading: "eager",
              fadeIn: false,
            }}
            rawImageProps={{
              height: 40,
            }}
          />
        </Box>
      ))}
    </Box>
  ))

  return (
    <Container maxWidth="lg" className="partners-section">
      <Box pt={2} pb={6}>
        <AnimateOnSeen
          animeConfig={{
            ...TRANSLATE_ANIME_CONFIG,
            targets: ".partners-section .partner-logo",
            delay: anime.stagger(100, { direction: "reverse" }),
          }}
        >
          {swipes.length > 1 ? (
            <AutoPlaySwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {swipesElements}
            </AutoPlaySwipeableViews>
          ) : (
            swipesElements
          )}
        </AnimateOnSeen>
        {swipes.length > 1 && (
          <Box display="flex" justifyContent="center">
            <MobileStepper
              variant="dots"
              steps={swipes.length}
              position="static"
              activeStep={activeStep}
            />
          </Box>
        )}
        <AnimateOnSeen animeConfig={TRANSLATE_ANIME_CONFIG}>
          <Box pt={1} className={classes.title}>
            <Typography
              variant="body1"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {title}
            </Typography>
          </Box>
        </AnimateOnSeen>
      </Box>
    </Container>
  )
}

export default Partners
