import { Box, Grid, Typography } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import { default as React } from "react"
import { TRANSLATE_ANIME_CONFIG } from "../constants"
import AnimateOnSeen from "./animate-on-seen"
import PersonaCard from "./persona-card"
import dotsGroupImg from "../img/dots-group.svg"

const useStyles = makeStyles(theme => ({
  carsContainer: {
    position: "relative",
  },
}))

const Personas = ({ title, description, items }) => {
  const classes = useStyles()
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.up("sm"))

  const [seen, setSeen] = React.useState(false)

  return (
    <Box py={sm ? 8 : 4} className="personas-section">
      <Box px={sm ? 12 : 2} pb={6}>
        <AnimateOnSeen
          animeConfig={{
            ...TRANSLATE_ANIME_CONFIG,
            targets: ".personas-section .title",
            delay: anime.stagger(100, { direction: "reverse" }),
          }}
        >
          <Typography
            className="title"
            variant="h2"
            align="center"
            color="textPrimary"
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            className="title"
            variant="subtitle1"
            align="center"
            color="textSecondary"
            gutterBottom
          >
            {description}
          </Typography>
        </AnimateOnSeen>
      </Box>
      <AnimateOnSeen
        animeConfig={{
          ...TRANSLATE_ANIME_CONFIG,
          targets: `.personas-section .persona-card`,
          delay: anime.stagger(100, { direction: "reverse" }),
          complete: () => {
            setSeen(true)
          },
        }}
      >
        <Grid
          className={classes.carsContainer}
          container
          justify="center"
          spacing={3}
        >
          <Box
            position="absolute"
            height={200}
            width={200}
            zIndex={-1}
            top={-theme.spacing(7)}
            left={sm ? -theme.spacing(5) : 0}
            style={{
              backgroundImage: `url(${dotsGroupImg})`,
              backgroundRepeat: "no-repeat",
            }}
          />
          <Box
            position="absolute"
            height={200}
            width={200}
            zIndex={-1}
            top={-theme.spacing(3)}
            right={sm ? -theme.spacing(3) : 0}
            style={{
              backgroundImage: `url(${dotsGroupImg})`,
              backgroundRepeat: "no-repeat",
            }}
          />
          {items &&
            !!items.length &&
            items.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Box
                  height="100%"
                  width="100%"
                  display="flex"
                  justifyContent="center"
                >
                  <PersonaCard
                    className="persona-card"
                    index={index}
                    enableAnimations={seen}
                    {...item}
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
      </AnimateOnSeen>
    </Box>
  )
}

export default Personas
