import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import { useTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import anime from "animejs/lib/anime.es.js"
import { default as React } from "react"
import SwipeableViews from "react-swipeable-views"
import { autoPlay } from "react-swipeable-views-utils"
import MobileStepper from "../components/MobileStepper"
import { SECTION_VERTICAL_PADDING, TRANSLATE_ANIME_CONFIG } from "../constants"
import AnimateOnSeen from "./animate-on-seen"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

export const StatisticsTemplate = ({
  title,
  description,
  data,
  collapsed = false,
  inheritColor = false,
}) => {
  const { items = [] } = data

  const theme = useTheme()
  const [activeStep, setActiveStep] = React.useState(0)
  const swipes = []

  const md = useMediaQuery(theme.breakpoints.up("md"))
  const lg = useMediaQuery(theme.breakpoints.up("lg"))

  let itemsPerSwipe

  if (collapsed) {
    itemsPerSwipe = 1
  } else if (lg) {
    itemsPerSwipe = 4
  } else if (md) {
    itemsPerSwipe = 2
  } else {
    itemsPerSwipe = 1
  }

  function handleStepChange(step) {
    setActiveStep(step)
  }

  let currentSwipe = { items: [] }

  items.forEach((item, index) => {
    if (index % itemsPerSwipe === 0) {
      if (currentSwipe.items.length) {
        swipes.push(currentSwipe)
        currentSwipe = { items: [] }
      }
    }

    currentSwipe.items.push(item)
  })

  if (currentSwipe.items.length) {
    swipes.push(currentSwipe)
  }

  const swipesElements = swipes.map((swipe, swipeIndex) => (
    <Box
      key={swipeIndex}
      py={4}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      {swipe.items.map((item, itemIndex) => (
        <Box
          key={itemIndex}
          className="counter-container"
          px={swipe.items.length ? 4 : 0}
        >
          <AnimateOnSeen
            animeConfig={{
              targets: `.statistics-section .counter-${swipeIndex}-${itemIndex}`,
              innerHTML: [0, item.count],
              round: 1,
              easing: "easeInOutExpo",
            }}
          >
            <Box display="flex" justifyContent="center">
              <Typography
                className={`counter-${swipeIndex}-${itemIndex}`}
                variant="h1"
                component="span"
                align="center"
                color={inheritColor ? "inherit" : "textPrimary"}
              >
                {item.count}
              </Typography>
              {item.unit && (
                <React.Fragment>
                  {" "}
                  <Typography
                    variant="h1"
                    component="span"
                    align="center"
                    color={inheritColor ? "inherit" : "textPrimary"}
                  >
                    {item.unit}
                  </Typography>
                </React.Fragment>
              )}
            </Box>
            <Divider />
            <Box pt={1}>
              <Typography
                variant="subtitle1"
                align="center"
                color={inheritColor ? "inherit" : "textSecondary"}
              >
                {item.description}
              </Typography>
            </Box>
          </AnimateOnSeen>
        </Box>
      ))}
    </Box>
  ))

  return (
    <Box
      py={SECTION_VERTICAL_PADDING}
      className="statistics-section"
      overflow="hidden"
      width="100%"
    >
      <AnimateOnSeen
        animeConfig={{
          ...TRANSLATE_ANIME_CONFIG,
          targets: ".statistics-section .title",
          delay: anime.stagger(100, { direction: "reverse" }),
        }}
      >
        {title && (
          <Typography
            className="title"
            variant="h1"
            component="h2"
            align="center"
            color={inheritColor ? "inherit" : "textPrimary"}
            gutterBottom
          >
            {title}
          </Typography>
        )}
        {description && (
          <Typography
            className="title"
            variant="h5"
            align="center"
            color={inheritColor ? "inherit" : "textSecondary"}
            gutterBottom
          >
            {description}
          </Typography>
        )}
      </AnimateOnSeen>
      <AnimateOnSeen
        animeConfig={{
          ...TRANSLATE_ANIME_CONFIG,
          targets: ".statistics-section .counter-container",
          delay: anime.stagger(100, { direction: "reverse" }),
        }}
      >
        {swipes.length > 1 ? (
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {swipesElements}
          </AutoPlaySwipeableViews>
        ) : (
          swipesElements
        )}
      </AnimateOnSeen>
      {swipes.length > 1 && (
        <Box display="flex" justifyContent="center">
          <MobileStepper
            variant="dots"
            steps={swipes.length}
            position="static"
            activeStep={activeStep}
          />
        </Box>
      )}
    </Box>
  )
}

StatisticsTemplate.protoTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  data: PropTypes.shape({
    items: PropTypes.array,
  }),
  collapsed: PropTypes.bool,
  inheritColor: PropTypes.bool,
}

const Statistics = ({ data, ...rest }) => {
  if (!data) {
    return null
  }

  return <StatisticsTemplate data={data.frontmatter} {...rest} />
}

export const query = graphql`
  fragment StatisticsFragment on Query {
    statisticsData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/statistics.md/" }
    ) {
      frontmatter {
        items {
          count
          description
          unit
        }
      }
    }
  }
`

export { Statistics }
